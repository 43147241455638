<template>
  <div>
    <vs-table v-model="selected" :data="Haiocloudlist">
      <template slot="header">
        <!-- <vs-button @click="$router.go(-1)" class="mb-4"> back</vs-button> -->
        <div></div>
      </template>
      <template slot="thead">
        <vs-th> title </vs-th>
        <vs-th> vps name </vs-th>
        <vs-th> cpu (core) </vs-th>
        <vs-th> ram (GB) </vs-th>
        <vs-th> hard(GB) </vs-th>
        <vs-th> node ip </vs-th>
        <vs-th> node name </vs-th>
        <vs-th> public ip </vs-th>
        <vs-th> ipid </vs-th>
        <vs-th> image </vs-th>
        <vs-th> created time </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].subject">
            {{ data[indextr].title }}
          </vs-td>
          <vs-td :data="data[indextr].user_fullname">
            {{ tr.vps_name }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].cpu }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].ram }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].storage }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].nodeIP }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].node }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].public_ip }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].ipid }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].image_title }} ** {{ tr.image_version }}
          </vs-td>
          <vs-td :data="data[indextr].updated_at">
            {{ data[indextr].created_at }}</vs-td
          >
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination class="my-5" :total="HC_LP" v-model="HC_PN"></vs-pagination>
  </div>
</template>
<script>
export default {
  props: {
    userID: { required: true },
  },
  data() {
    return {
      Haiocloudlist: "",
      selected: "",
      //===PAGINATION===
      HC_LP: 1,
      HC_PN: 1,
      //===PAGINATION===
    };
  },
  watch: {
    selected() {
      this.$emit("selected", this.selected);
    },
  },
  methods: {
    getHaiocloudlist() {
      this.$http
        .get(
          `/admin/haioclouds/?user_id=${
            this.userID || this.$route.query.id
          }&page=${this.pageNumber}`
        )
        .then((res) => {
          this.HC_LP = res.data.params.last_page;
          this.Haiocloudlist = res.data.params.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doubleSelection() {
      this.$router.push(`/user?id=${this.selectedhost.user_id}`);
    },
  },
  created() {
    this.getHaiocloudlist();
  },
  mounted() {
    this.getHaiocloudlist();
  },
};
</script>